<template>
  <controller-product id="reviewFormSection" class="pt-40px md:pt-100px">
    <div class="content-container">
      <div class="pb-18px border-b border-solid">
        <div>
          <h1 class="font-pragmatica-extended text-3.6rem leading-5rem">
            {{ $t('hsc.review.reviews') }}
          </h1>
        </div>
      </div>
      <div>
        <controller-user-comment
          ref="rating"
          v-slot="{ form, commentSettings, submit, consents }"
          class="grid grid-cols-12 lg:gap-5 pt-40px pb-12 lg:pb-100px"
          @submitted="messageSent"
        >
          <div
            class="col-span-12 lg:col-span-6"
            :class="{ 'col-span-6 lg:col-span-12': !formActive }"
          >
            <div class="flex items-start flex-col md:flex-row">
              <div class="bg-darkGrayishViolet md:max-w-530p w-full p-20px">
                <p
                  class="font-pragmatica-extended text-center md:text-left text-h3 text-white mb-22px"
                >
                  {{ $t('hsc.review.customerRating') }}
                </p>
                <div
                  v-if="parseFloat(commentSettings.currentAverage).toFixed(1)"
                  class="flex flex-col md:flex-row md:space-x-20px items-center"
                >
                  <RatingStarsComponent
                    :bg-gray="true"
                    :rating="
                      parseFloat(commentSettings.currentAverage).toFixed(1)
                    "
                    class="font-pragmatica text-h3 mr-0"
                  />
                  <span class="font-pragmatica text-h3 font-bold text-white"
                    >4,0 / 5,0</span
                  >
                  <span class="text-white font-normal text-p"
                    >({{ commentSettings.totalRatingCount
                    }}<span class="font-pragmatica text-p">
                      {{ $t('hsc.review.reviews') }}</span
                    >)</span
                  >
                </div>
                <div v-else>
                  <div class="flex items-center gap-3">
                    <RatingStarsComponent
                      class="mr-0"
                      :bg-gray="true"
                      :rating="0"
                    />
                    <span class="font-pragmatica text-p1">{{
                      $t('hsc.review.reviews')
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                v-if="!formActive"
                class="mt-20px md:mt-0 md:ml-20px lg:ml-auto"
              >
                <multiple-bordered-button
                  primary-color-name="pureYellow"
                  text-color-name="darkGrayishViolet"
                  @click.native="showReviewForm"
                >
                  <span class="btn-text uppercase">{{
                    $t('hsc.form.actions.addReview')
                  }}</span>
                </multiple-bordered-button>
              </div>
            </div>
            <controller-comment-list v-slot="{ items }" class="pt-10">
              <template v-if="items && items.length">
                <div v-for="item in items" :key="item.id" class="">
                  <div
                    class="border-b border-solid border-lightGrayishBlue pb-5 mb-5"
                  >
                    <div class="flex items-center space-x-3">
                      <h6 class="text-p font-bold">
                        {{ item.title }}
                      </h6>
                      <p class="text-p text-texteco-700">
                        <span class="text-lightGray">{{
                          parseCity(item)
                        }}</span>
                        ,
                        <span class="text-lightGray">
                          {{ parseCountry(item) }}</span
                        >
                      </p>
                      <RatingStarsComponent :rating="+item.rating" />
                    </div>
                    <div class="pt-2">
                      <p class="text-p">
                        {{ parseContent(item) }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </controller-comment-list>
          </div>
          <div v-if="formActive" class="col-span-12 lg:col-span-6">
            <div class="mt-10 md:mt-0 bg-whiteSmoke">
              <form @submit.prevent="handleSubmit(submit)">
                <div class="px-18px">
                  <p class="font-pragmatica-extended text-h3 pt-20px pb-40px">
                    {{ $t('hsc.review.leaveReview') }}
                  </p>
                  <div>
                    <p class="pb-10px text-p1">Rating*</p>
                    <div class="pb-16px">
                      <RatingStarsComponent
                        ref="ratingComponent"
                        :bg-gray="true"
                        :return-value="true"
                        @change="setRatingValue"
                      />
                    </div>
                    <p
                      v-if="errors.rating"
                      class="error-msg relative"
                      style="transform: translateY(-15px)"
                    >
                      Ovo polje je obavezno
                    </p>
                  </div>
                  <label class="w-full">
                    <p class="text-p1">
                      {{ $t('hsc.form.inputs.firstName') }}*
                    </p>
                    <div class="pb-12px">
                      <input
                        v-model="form.title"
                        class="border border-solid rounded-md h-40px w-full pl-10px text-p"
                        type="text"
                        placeholder="First name"
                      />
                      <p v-if="errors.title" class="error-msg relative">
                        Ovo polje je obavezno
                      </p>
                    </div>
                  </label>
                  <div>
                    <div class="grid col-span-3 pb-12px">
                      <div class="flex gap-12px">
                        <label class="w-full">
                          <p class="text-p1">
                            {{ $t('hsc.form.inputs.city') }}*
                          </p>
                          <input
                            v-model="formData.city"
                            class="border border-solid rounded-md text-p h-40px w-full pl-10px"
                            type="text"
                            placeholder="City"
                          />
                          <p v-if="errors.city" class="error-msg relative">
                            Ovo polje je obavezno
                          </p>
                        </label>
                        <label class="w-full">
                          <p class="text-p1">
                            {{ $t('hsc.form.inputs.country') }}*
                          </p>
                          <input
                            v-model="formData.country"
                            class="border border-solid rounded-md h-40px w-full pl-10px text-p"
                            type="text"
                            placeholder="Country"
                          />
                          <p v-if="errors.country" class="error-msg relative">
                            Ovo polje je obavezno
                          </p>
                        </label>
                      </div>
                    </div>
                    <label class="w-full">
                      <p class="text-p1">
                        {{ $t('hsc.form.inputs.message') }}*
                      </p>
                      <div class="pb-12px">
                        <textarea
                          v-model="formData.content"
                          rows="10"
                          cols="60"
                          class="border border-solid rounded-md w-full pt-10px pl-10px text-p"
                          placeholder="Type Your Message"
                        />
                        <p v-if="errors.content" class="error-msg relative">
                          Ovo polje je obavezno
                        </p>
                      </div>
                    </label>
                    <div
                      v-for="consent in consents"
                      :key="consent.uniqueNumber"
                      class=""
                    >
                      <input
                        v-model="form.acceptedConsents"
                        type="checkbox"
                        :value="consent.uniqueNumber"
                      />
                      <div v-html="consent.displayName" />
                    </div>
                    <div class="flex w-full pb-20px">
                      <button
                        class="border border-solid border-black rounded-100px uppercase h-40px w-full max-w-170px text-p"
                        @click="closeReviewForm"
                      >
                        {{ $t('hsc.form.actions.cancel') }}
                      </button>
                      <div class="pl-32px w-full text-p">
                        <button
                          class="h-40px w-full max-w-170px bg-pureYellow rounded-100px text-p uppercase text-white"
                        >
                          {{ $t('hsc.form.actions.send') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </controller-user-comment>
      </div>
    </div>
  </controller-product>
</template>

<script>
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import RatingStarsComponent from '@/components/ui/inputs/RatingStarsComponent.vue'
import ControllerUserComment from 'Controllers/ControllerUserComment'
import ControllerCommentList from 'Controllers/ControllerCommentList'
import ControllerProduct from 'Controllers/ControllerProduct'

export default {
  components: {
    ControllerProduct,
    RatingStarsComponent,
    ControllerUserComment,
    ControllerCommentList,
    MultipleBorderedButton,
  },
  data() {
    return {
      isSubmited: false,
      formActive: false,
      formData: {
        city: '',
        country: '',
        content: '',
      },
      errors: {
        rating: false,
        city: false,
        country: false,
        title: false,
        content: false,
      },
    }
  },
  watch: {
    'formData.city': {
      handler(v) {
        let content = this.$refs.rating.form.content
        if (v) {
          content = ` City-${this.formData.city} Country-${this.formData.country} Content-${this.formData.content}`
          content.replace(/~City([a-z])~(.*?)(?=~|$)/g, content)
          this.$set(this.$refs.rating.form, 'content', content)
        }
      },
    },
    'formData.country': {
      handler(v) {
        let content = this.$refs.rating.form.content
        if (v) {
          content = ` City-${this.formData.city} Country-${this.formData.country} Content-${this.formData.content}`
          content.replace(/~Country([a-z])~(.*?)(?=~|$)/g, content)
          this.$set(this.$refs.rating.form, 'content', content)
        }
      },
    },
    'formData.content': {
      handler(v) {
        let content = this.$refs.rating.form.content
        if (v) {
          content = ` City-${this.formData.city} Country-${this.formData.country} Content-${this.formData.content}`
          content.replace(/~Content([a-z])~(.*?)(?=~|$)/g, content)
          this.$set(this.$refs.rating.form, 'content', content)
        }
      },
    },
    formActive(v) {
      document
        .getElementById('reviewFormSection')
        .scrollIntoView({ behavior: 'smooth' })
    },
    isSubmited(v) {
      if (v) {
        this.handleErrors()
        return false
      }
      this.resetForm()
    },
  },
  mounted() {
    this.$root.$on('leave-review-form', (v) => {
      this.formActive = v
    })
  },
  methods: {
    resetForm(bolean = false) {
      for (const prop in this.formData) {
        this.formData[prop] = ''
      }
      for (const [key] of Object.entries(this.$refs.rating.form)) {
        if (key === 'rating') {
          this.$set(this.$refs.rating.form, `${key}`, null)
        }
        this.$set(this.$refs.rating.form, `${key}`, '')
      }
      this.$set(this.$refs.ratingComponent, 'index', 0)
      this.$set(this.$refs.ratingComponent, 'hoverIndex', 0)
      if (!bolean) {
        for (const prop in this.errors) {
          this.errors[prop] = false
        }
        this.$set(this.$refs.rating.form, `rating`, null)
      }
    },
    closeReviewForm() {
      this.resetForm()
      this.formActive = false
      this.isSubmited = false
    },
    handleErrors() {
      for (const prop in this.formData) {
        this.errors[prop] = !this.formData[prop] || this.formData[prop] === ''
      }
      this.errors.rating = !this.$refs.rating.form.rating
      this.errors.title = this.$refs.rating.form.title === ''
    },
    handleSubmit(submit) {
      this.handleErrors()
      let hasError = false
      for (const prop in this.errors) {
        if (this.errors[prop]) {
          hasError = true
        }
      }
      if (hasError) {
        this.$i18n.locale === 'en'
          ? this.$toast.error('Check all fields and try again')
          : this.$toast.error('Provjerite sva polja i pokušajte ponovno')
        return
      }
      submit()
      setTimeout(() => {
        this.resetForm(false)
      }, 500)
    },
    showReviewForm() {
      this.formActive = true
    },
    parseCity(v) {
      if (v.content && v.content.trim().split(' ')[0].split('-')[1]) {
        return v.content.trim().split(' ')[0].split('-')[1]
      }
      return ''
    },
    parseCountry(v) {
      if (v.content && v.content.trim().split(' ')[1].split('-')[1]) {
        return v.content.trim().split(' ')[1].split('-')[1]
      }
      return ''
    },
    parseContent(v) {
      if (
        v.content &&
        v.content.trim().split(' ') &&
        v.content.trim().split(' ').slice(2) &&
        v.content.trim().split(' ').slice(2).join(' ').replace('Content-', '')
      ) {
        return v.content
          .trim()
          .split(' ')
          .slice(2)
          .join(' ')
          .replace('Content-', '')
      }
      return ''
    },
    messageSent() {
      this.$set(this.$refs.ratingComponent, 'index', 0)
      this.$set(this.$refs.ratingComponent, 'hoverIndex', 0)
      for (const [key] of Object.entries(this.$refs.rating.form)) {
        if (key === 'rating') {
          this.$set(this.$refs.rating.form, `${key}`, null)
        }
        this.$set(this.$refs.rating.form, `${key}`, '')
      }
      for (const [key] of Object.entries(this.formData)) {
        this.formData[key] = ''
      }
      this.$toast.success(
        this.$i18n.locale === 'en'
          ? 'Your review has been successfully submitted! Thank you! Review must be approved before it is published.'
          : 'Vaša recenzija je uspiješno poslana! Hvala! Recenzija će se objaviti nakon odobrenja administratora!',
        {
          theme: 'toasted-primary',
          position: 'top-right',
          duration: 10000,
        }
      )
      this.formActive = false
    },
    setRatingValue(index) {
      if (this.$refs.rating && this.$refs.rating.form) {
        this.$set(this.$refs.rating.form, 'rating', index)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.error-msg {
  @apply relative #{'!important'};
}
</style>
