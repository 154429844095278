<template>
  <div
    class="relative overflow-hidden sm:min-h-screen lg:max-h-auto bg-contain lg:bg-auto bg-center bg-no-repeat flex items-center justify-center"
    :class="`bg-${bgColor}`"
  >
    <hexagon-svg
      class="absolute top-0 w-full h-full"
      :light="useLightHexagon"
    />
    <div class="relative content-container py-16 sm:py-40">
      <div class="mx-auto text-center" :class="`text-${textColor}`">
        <editor-tip-tap
          name="productTitle"
          content-class="main-title overflow-hidden font-extrabold leading-tight font-pragmatica-extended"
        />

        <editor-tip-tap
          name="productSubtitle"
          class="main-subtitle mb-5 text-pureYellow leading-tight font-pragmatica-extended md:mb-16"
        />

        <editor-image
          name="productImage"
          class="slika inline-block mb-5 md:mb-10 px-3"
        />

        <editor-tip-tap
          name="productDescription"
          class="mb-6 text-lg font-pragmatica md:text-1.6rem md:mb-16"
        />

        <div
          class="flex items-center justify-center space-x-6 md:space-x-4.5rem"
        >
          <multiple-bordered-button
            :primary-color-name="textColor"
            :text-color-name="textColor"
            :is-transparent="true"
          >
            <template #default>
              <editor-link
                v-slot="{ name, path }"
                name="productLearnMoreLink"
                class="w-full h-full"
              >
                <cms-link role="button" :to="path" class="btn-text">
                  {{ name }}
                </cms-link>
              </editor-link>
            </template>
          </multiple-bordered-button>
          <multiple-bordered-button
            primary-color-name="pureYellow"
            text-color-name="darkGrayishViolet"
          >
            <template #default>
              <editor-link
                v-slot="{ name, path }"
                name="productBuyLink"
                class="w-full h-full"
              >
                <cms-link :to="path" role="button" class="btn-text">
                  {{ name }}
                </cms-link>
              </editor-link>
            </template>
          </multiple-bordered-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import EditorTipTap from 'Editors/EditorTipTap'
import EditorLink from 'Editors/EditorLink'
import EditorImage from 'Editors/EditorImage'
import HexagonSvg from '@/components/ui/svg/HexagonSvg'
export default {
  components: {
    EditorTipTap,
    EditorLink,
    EditorImage,
    MultipleBorderedButton,
    HexagonSvg,
  },

  data() {
    return {
      bgColor: 'white',
      textColor: 'darkGrayishViolet',
      useLightHexagon: true,
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep .slika {
  img {
    @apply mx-auto;
    width: 400px;
    max-width: 66vw;
    max-height: 400px;
    transform: rotate(90deg) translateX(-40px);
  }
}
::v-deep img {
  @apply h-full w-full object-center object-contain;
}
</style>
