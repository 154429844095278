<template>
  <editor-image
    in-background
    name="heroBackground"
    class="heroBackground pb-20 lg:min-h-100vh-mobile-menu bg-no-repeat bg-35%-center bg-cover flex items-center pt-12 md:pb-24 xl:pt-20 xl:pb-40"
  >
    <div class="content-container">
      <editor-tip-tap
        name="heroTitle"
        content-class="main-title mb-6 overflow-hidden font-extrabold leading-tight font-pragmatica-extended sm:max-w-61rem md:mb-12"
      />
      <div class="md:max-w-50rem">
        <editor-tip-tap
          name="heroSubtitle"
          class="main-subtitle mb-5 text-pureYellow leading-tight font-pragmatica-extended md:mb-10"
        />
        <editor-tip-tap
          name="heroDescription"
          class="mb-8 text-xl leading-loose font-pragmatica-extended md:text-1.6rem md:mb-4.5rem"
        />

        <div class="flex items-center">
          <multiple-bordered-button
            primary-color-name="pureYellow"
            text-color-name="darkGrayishViolet"
          >
            <template #default>
              <editor-link
                v-slot="{ name, path }"
                name="heroShopLink"
                class="w-full h-full"
              >
                <cms-link :to="path" class="btn-text">
                  {{ name }}
                </cms-link>
              </editor-link>
            </template>
          </multiple-bordered-button>

          <editor-tip-tap
            name="heroShortDescription"
            class="h-full w-full pl-4 ml-6 border-l border-darkGrayishViolet text-lg leading-tight md:text-1.4rem md:pl-8 md:ml-12"
          />
        </div>
      </div>
    </div>
  </editor-image>
</template>

<script>
import EditorTipTap from 'Editors/EditorTipTap'
import EditorLink from 'Editors/EditorLink'
import EditorImage from 'Editors/EditorImage'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'

export default {
  components: {
    EditorTipTap,
    EditorLink,
    EditorImage,
    MultipleBorderedButton,
  },
}
</script>
<style>
.heroBackground {
  background-position: 10000px;
  @screen lg {
    background-position: center;
  }
}
</style>
