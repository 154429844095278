<template>
  <editor-image
    id="ProdDescSection"
    in-background
    name="productInfoBackgroundImage"
    class="bg-no-repeat bg-cover bg-center min-h-screen flex items-center justify-center"
  >
    <div class="content-container py-12 sm:py-40 lg:py-17rem">
      <div class="w-full md:max-w-45rem md:ml-auto">
        <editor-tip-tap
          name="title"
          class="main-subtitle mb-5 font-pragmatica-extended leading-tight md:mb-8"
        />

        <editor-tip-tap
          name="productDescriptionText"
          class="text-lg font-pragmatica-extended leading-loose md:text-1.6rem mb-10"
        />
      </div></div
  ></editor-image>
</template>

<script>
import EditorTipTap from 'Editors/EditorTipTap'
import EditorImage from 'Editors/EditorImage'
export default {
  components: {
    EditorTipTap,
    EditorImage,
  },
  async mounted() {
    await this.$nextTick()
    this.scrollTo()
  },
  methods: {
    scrollTo() {
      if (!this.$route?.query?.scroll) return
      const detailsElement = document.getElementById('ProdDescSection')
      if (detailsElement) {
        detailsElement.scrollIntoView({ behavior: 'smooth' })
      }
      this.$router.replace({ query: null })
    },
  },
}
</script>
