<template>
  <svg
    ref="svgEl"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1030"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      opacity="0.65"
      stroke-width="2.4"
      :stroke="light ? 'rgba(215,216,218, .35)' : '#707070'"
      fill="none"
    >
      <path
        d="M 482.7 645.014 L 391.208 592.192 A 30.17 30.17 0 0 1 376.167 566.138 V 460.493 A 30.165 30.165 0 0 1 391.209 434.44 L 482.7 381.618 A 30.076 30.076 0 0 1 512.783 381.618 L 604.276 434.441 A 30.164 30.164 0 0 1 619.317 460.494 V 566.138 A 30.165 30.165 0 0 1 604.276 592.192 L 512.784 645.014 A 30.081 30.081 0 0 1 482.7 645.014"
      />

      <path
        d="M 474.2 659.033 L 382.71 606.212 A 47.224 47.224 0 0 1 359.165 565.429 V 459.784 A 47.218 47.218 0 0 1 382.711 419 L 474.2 366.18 A 47.091 47.091 0 0 1 521.289 366.18 L 612.785 419 A 47.216 47.216 0 0 1 636.331 459.781 V 565.43 A 47.225 47.225 0 0 1 612.785 606.213 L 521.293 659.035 A 47.091 47.091 0 0 1 474.2 659.035"
      />

      <path
        opacity="0.95"
        d="M 465.701 673.053 L 374.21 620.232 A 64.283 64.283 0 0 1 342.161 564.719 V 459.075 A 64.275 64.275 0 0 1 374.211 403.563 L 465.7 350.741 A 64.1 64.1 0 0 1 529.794 350.741 L 621.294 403.567 A 64.266 64.266 0 0 1 653.344 459.077 V 564.721 A 64.281 64.281 0 0 1 621.294 620.234 L 529.8 673.056 A 64.1 64.1 0 0 1 465.7 673.056"
      />

      <path
        opacity="0.95"
        d="M 457.192 687.78 L 365.7 634.964 A 81.345 81.345 0 0 1 325.146 564.721 V 459.075 A 81.328 81.328 0 0 1 365.7 388.834 L 457.19 336.013 A 81.109 81.109 0 0 1 538.29 336.007 L 629.79 388.836 A 81.321 81.321 0 0 1 670.344 459.075 V 564.721 A 81.332 81.332 0 0 1 629.79 634.963 L 538.3 687.785 A 81.1 81.1 0 0 1 457.191 687.785"
      />

      <path
        opacity="0.9"
        d="M 448.685 702.51 L 357.195 649.69 A 98.4 98.4 0 0 1 308.139 564.718 V 459.075 A 98.381 98.381 0 0 1 357.2 374.1 L 448.688 321.279 A 98.117 98.117 0 0 1 546.794 321.273 L 638.294 374.103 A 98.375 98.375 0 0 1 687.352 459.071 V 564.721 A 98.39 98.39 0 0 1 638.3 649.693 L 546.8 702.514 A 98.117 98.117 0 0 1 448.682 702.514"
      />

      <path
        opacity="0.9"
        d="M 440.186 717.242 L 348.696 664.422 A 115.456 115.456 0 0 1 291.136 564.722 V 459.075 A 115.441 115.441 0 0 1 348.702 359.375 L 440.188 306.556 A 115.131 115.131 0 0 1 555.301 306.549 L 646.801 359.38 A 115.428 115.428 0 0 1 704.363 459.08 V 564.721 A 115.441 115.441 0 0 1 646.801 664.421 L 555.31 717.244 A 115.126 115.126 0 0 1 440.184 717.244"
      />

      <path
        opacity="0.85"
        d="M 431.68 731.971 L 340.19 679.152 A 132.513 132.513 0 0 1 274.126 564.721 V 459.075 A 132.493 132.493 0 0 1 340.2 344.644 L 431.687 291.825 A 132.134 132.134 0 0 1 563.805 291.817 L 655.305 344.649 A 132.477 132.477 0 0 1 721.37 459.075 V 564.721 A 132.5 132.5 0 0 1 655.3 679.15 L 563.809 731.973 A 132.14 132.14 0 0 1 431.674 731.973"
      />

      <path
        opacity="0.8"
        d="M 423.175 746.7 L 331.687 693.882 A 149.572 149.572 0 0 1 257.117 564.721 V 459.075 A 149.547 149.547 0 0 1 331.692 329.914 L 423.178 277.1 A 149.139 149.139 0 0 1 572.302 277.091 L 663.808 329.925 A 149.53 149.53 0 0 1 738.377 459.08 V 564.721 A 149.557 149.557 0 0 1 663.808 693.881 L 572.317 746.7 A 149.141 149.141 0 0 1 423.175 746.7"
      />

      <path
        opacity="0.75"
        d="M 414.67 761.428 L 323.183 708.611 A 166.627 166.627 0 0 1 240.11 564.721 V 459.075 A 166.6 166.6 0 0 1 323.19 315.183 L 414.674 262.366 A 166.154 166.154 0 0 1 580.804 262.355 L 672.312 315.19 A 166.584 166.584 0 0 1 755.385 459.075 V 564.721 A 166.613 166.613 0 0 1 672.312 708.61 L 580.82 761.432 A 166.143 166.143 0 0 1 414.67 761.432"
      />

      <path
        opacity="0.7"
        d="M 406.161 776.158 L 314.679 723.34 A 183.684 183.684 0 0 1 223.1 564.721 V 459.075 A 183.661 183.661 0 0 1 314.685 300.454 L 406.168 247.636 A 183.162 183.162 0 0 1 589.304 247.625 L 680.814 300.462 A 183.632 183.632 0 0 1 772.391 459.075 V 564.721 A 183.665 183.665 0 0 1 680.814 723.339 L 589.322 776.161 A 183.16 183.16 0 0 1 406.163 776.161"
      />

      <path
        opacity="0.65"
        d="M 397.658 790.886 L 306.176 738.07 A 200.743 200.743 0 0 1 206.094 564.721 V 459.075 A 200.717 200.717 0 0 1 306.184 285.724 L 397.666 232.907 A 200.177 200.177 0 0 1 597.808 232.894 L 689.32 285.732 A 200.687 200.687 0 0 1 789.4 459.075 V 564.721 A 200.72 200.72 0 0 1 689.32 738.068 L 597.829 790.891 A 200.169 200.169 0 0 1 397.66 790.886 "
      />

      <path
        opacity="0.6"
        d="M 389.155 805.616 L 297.671 752.8 A 217.8 217.8 0 0 1 189.086 564.721 V 459.075 A 217.775 217.775 0 0 1 297.686 270.993 L 389.168 218.178 A 217.174 217.174 0 0 1 606.315 218.164 L 697.824 271 A 217.738 217.738 0 0 1 806.408 459.075 V 564.721 A 217.775 217.775 0 0 1 697.824 752.8 L 606.333 805.622 A 217.177 217.177 0 0 1 389.155 805.622"
      />

      <path
        opacity="0.55"
        d="M 380.651 820.345 L 289.167 767.529 A 234.861 234.861 0 0 1 172.078 564.721 V 459.075 A 234.831 234.831 0 0 1 289.178 256.264 L 380.659 203.448 A 234.189 234.189 0 0 1 614.812 203.434 L 706.328 256.275 A 234.786 234.786 0 0 1 823.416 459.075 V 564.721 A 234.831 234.831 0 0 1 706.327 767.527 L 614.836 820.349 A 234.184 234.184 0 0 1 380.651 820.349 "
      />

      <path
        opacity="0.5"
        d="M 372.146 835.073 L 280.664 782.258 A 251.919 251.919 0 0 1 155.071 564.721 V 459.075 A 251.885 251.885 0 0 1 280.671 241.534 L 372.15 188.719 A 251.2 251.2 0 0 1 623.31 188.703 L 714.829 241.546 A 251.837 251.837 0 0 1 840.424 459.075 V 564.721 A 251.882 251.882 0 0 1 714.831 782.256 L 623.34 835.078 A 251.2 251.2 0 0 1 372.146 835.078"
      />

      <path
        opacity="0.45"
        d="M 363.643 849.8 L 272.161 796.984 A 268.968 268.968 0 0 1 138.061 564.718 V 459.075 A 268.939 268.939 0 0 1 272.171 226.8 L 363.65 173.984 A 268.211 268.211 0 0 1 631.816 173.967 L 723.338 226.812 A 268.893 268.893 0 0 1 857.432 459.075 V 564.721 A 268.939 268.939 0 0 1 723.332 796.985 L 631.841 849.808 A 268.2 268.2 0 0 1 363.641 849.803 "
      />

      <path
        opacity="0.4"
        d="M 355.138 864.532 L 263.657 811.717 A 286.031 286.031 0 0 1 121.057 564.717 V 459.075 A 285.991 285.991 0 0 1 263.67 212.075 L 355.149 159.259 A 285.214 285.214 0 0 1 640.32 159.241 L 731.845 212.087 A 285.946 285.946 0 0 1 874.445 459.074 V 564.721 A 285.994 285.994 0 0 1 731.845 811.714 L 640.354 864.537 A 285.213 285.213 0 0 1 355.144 864.532 "
      />

      <path
        opacity="0.35"
        d="M 346.634 879.261 L 255.153 826.447 A 303.09 303.09 0 0 1 104.047 564.721 V 459.075 A 303.047 303.047 0 0 1 255.164 197.346 L 346.643 144.532 A 302.216 302.216 0 0 1 648.82 144.512 L 740.347 197.359 A 303 303 0 0 1 891.447 459.075 V 564.721 A 303.049 303.049 0 0 1 740.347 826.444 L 648.856 879.265 A 302.215 302.215 0 0 1 346.638 879.265 "
      />

      <path
        opacity="0.3"
        d="M 338.129 893.989 L 246.65 841.177 A 320.148 320.148 0 0 1 87.039 564.721 V 459.075 A 320.1 320.1 0 0 1 246.661 182.617 L 338.139 129.8 A 319.232 319.232 0 0 1 657.321 129.779 L 748.851 182.627 A 320.053 320.053 0 0 1 908.451 459.073 V 564.721 A 320.106 320.106 0 0 1 748.847 841.173 L 657.355 893.995 A 319.221 319.221 0 0 1 338.129 893.989"
      />

      <path
        opacity="0.25"
        d="M 329.625 908.721 L 238.146 855.908 A 337.2 337.2 0 0 1 70.032 564.721 V 459.075 A 337.156 337.156 0 0 1 238.159 167.887 L 329.635 115.073 A 336.239 336.239 0 0 1 665.824 115.051 L 757.355 167.901 A 337.1 337.1 0 0 1 925.463 459.075 V 564.721 A 337.163 337.163 0 0 1 757.351 855.9 L 665.86 908.724 A 336.232 336.232 0 0 1 329.625 908.719 "
      />

      <path
        opacity="0.2"
        d="M 321.12 923.447 L 229.643 870.635 A 354.262 354.262 0 0 1 53.023 564.721 V 459.075 A 354.21 354.21 0 0 1 229.656 153.158 L 321.131 100.343 A 353.251 353.251 0 0 1 674.326 100.32 L 765.859 153.172 A 354.154 354.154 0 0 1 942.471 459.072 V 564.721 A 354.221 354.221 0 0 1 765.855 870.633 L 674.364 923.454 A 353.239 353.239 0 0 1 321.12 923.447 "
      />

      <path
        opacity="0.15"
        d="M 312.617 938.177 L 221.139 885.366 A 371.321 371.321 0 0 1 36.016 564.721 V 459.075 A 371.265 371.265 0 0 1 221.152 138.428 L 312.628 85.615 A 370.249 370.249 0 0 1 682.828 85.59 L 774.365 138.443 A 371.206 371.206 0 0 1 959.479 459.075 V 564.721 A 371.27 371.27 0 0 1 774.359 885.361 L 682.868 938.183 A 370.246 370.246 0 0 1 312.617 938.177 "
      />

      <path
        opacity="0.1"
        d="M 304.111 952.906 L 212.634 900.094 C 93.2 831.122 19.008 702.614 19.008 564.721 V 459.075 A 388.324 388.324 0 0 1 212.65 123.7 L 304.124 70.888 A 387.263 387.263 0 0 1 691.33 70.862 L 782.869 123.717 C 902.3 192.641 976.487 321.145 976.487 459.075 V 564.721 A 388.325 388.325 0 0 1 782.862 900.09 L 691.371 952.912 A 387.258 387.258 0 0 1 304.112 952.906 "
      />

      <path
        opacity="0.05"
        d="M 295.613 967.635 L 204.138 914.824 C 79.453 842.822 2 708.67 2 564.721 V 459.075 C 2 315.1 79.457 180.948 204.146 108.968 L 295.619 56.156 A 404.278 404.278 0 0 1 699.831 56.128 L 791.374 108.985 C 916.046 180.939 993.495 315.085 993.495 459.075 V 564.721 C 993.495 708.687 916.043 842.837 791.366 914.821 L 699.875 967.643 A 404.267 404.267 0 0 1 295.608 967.636 "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'HexagonSvg',

  props: {
    light: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$nuxt.$on('custom-scroll', this.handleScroll)
    this.$nuxt.$nextTick(() => {
      this.handleScroll()
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('custom-scroll')
  },
  methods: {
    handleScroll() {
      const svgEl = this.$refs.svgEl
      const svgRect = svgEl.getBoundingClientRect()
      const viewportHeight = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      )

      const isInViewport =
        svgRect.top >= -(viewportHeight * 0.5) &&
        svgRect.bottom <= viewportHeight * 1.5

      if (
        svgEl.classList.contains(
          this.light ? 'animated-light' : 'animated-dark'
        )
      ) {
        if (isInViewport) return
        svgEl.classList.remove(this.light ? 'animated-light' : 'animated-dark')
      } else {
        if (!isInViewport) return
        svgEl.classList.add(this.light ? 'animated-light' : 'animated-dark')
      }
    },
  },
}
</script>

<style scoped>
.animated-dark path {
  animation: pulse-dark 1.8s ease-in-out infinite;
}
.animated-light path {
  animation: pulse-light 1.8s ease-in-out infinite;
}

@keyframes pulse-dark {
  0% {
    stroke: #707070;
  }
  10% {
    stroke: #f0f0f0;
  }

  80% {
    stroke: #707070;
  }
}

@keyframes pulse-light {
  0% {
    stroke: rgba(215, 216, 218, 0.35);
  }
  10% {
    stroke: #3b7093;
  }

  80% {
    stroke: rgba(215, 216, 218, 0.35);
  }
}

path:nth-child(2) {
  animation-delay: 0.1s;
}
path:nth-child(3) {
  animation-delay: 0.2s;
}
path:nth-child(4) {
  animation-delay: 0.3s;
}
path:nth-child(5) {
  animation-delay: 0.4s;
}
path:nth-child(6) {
  animation-delay: 0.5s;
}
path:nth-child(7) {
  animation-delay: 0.575s;
}
path:nth-child(8) {
  animation-delay: 0.65s;
}
path:nth-child(9) {
  animation-delay: 0.725s;
}
path:nth-child(10) {
  animation-delay: 0.8s;
}
path:nth-child(11) {
  animation-delay: 0.875s;
}
path:nth-child(12) {
  animation-delay: 0.925s;
}
path:nth-child(13) {
  animation-delay: 0.975s;
}
path:nth-child(14) {
  animation-delay: 1.025s;
}
path:nth-child(15) {
  animation-delay: 1.075s;
}
path:nth-child(16) {
  animation-delay: 1.125s;
}
path:nth-child(17) {
  animation-delay: 1.175s;
}
path:nth-child(18) {
  animation-delay: 1.225s;
}
path:nth-child(19) {
  animation-delay: 1.275s;
}
path:nth-child(20) {
  animation-delay: 1.325s;
}
path:nth-child(21) {
  animation-delay: 1.35s;
}
path:nth-child(22) {
  animation-delay: 1.375s;
}
path:nth-child(23) {
  animation-delay: 1.4s;
}
</style>
