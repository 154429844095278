<template>
  <div id="prod_details" class="min-h-screen flex items-center justify-center">
    <div class="content-container py-16 lg:py-48 flex flex-col lg:flex-row">
      <editor-tip-tap
        name="gridTitle"
        class="main-subtitle w-full mb-8 font-pragmatica-extended font-medium leading-tight text-center md:w-1/3 md:text-left md:mt-38rem md:px-3 md:mb-0"
      />

      <editor-list
        name="firstImageColumn"
        :item-options="options"
        class="w-full md:w-1/3 md:px-3"
      >
        <template #list="{ items }">
          <!-- Top Image and Description -->
          <editor-list-item
            v-for="(item, index) in items"
            :key="item.$id"
            :item-id="item.$id"
            class="w-full flex items-center mb-5 flex-col"
            :class="[index === items.length - 1 ? 'md:mb-0' : 'md:mb-48']"
          >
            <div v-if="item.image" class="image-container mb-6 md:mb-0">
              <cms-image :media="item.image" class="h-full w-full" />
            </div>
            <div v-if="item.title || item.description" class="text-container">
              <p v-if="item.title" class="item-title">
                {{ item.title }}
              </p>
              <p v-if="item.description" class="description-text">
                {{ item.description }}
              </p>
            </div>
          </editor-list-item></template
        >
      </editor-list>

      <editor-list
        name="secondImageColumn"
        :item-options="options"
        class="w-full md:w-1/3 md:px-3 md:mt-64 xl:mt-24rem"
      >
        <template #list="{ items }">
          <!-- Top Image and Description -->
          <editor-list-item
            v-for="(item, index) in items"
            :key="item.$id"
            :item-id="item.$id"
            class="w-full flex items-center mb-5 flex-col"
            :class="[index === items.length - 1 ? 'md:mb-0' : 'md:mb-48']"
          >
            <div v-if="item.image" class="image-container mb-6 md:mb-0">
              <cms-image :media="item.image" class="h-full w-full" />
            </div>
            <div v-if="item.title || item.description" class="text-container">
              <p v-if="item.title" class="item-title">
                {{ item.title }}
              </p>
              <p v-if="item.description" class="description-text">
                {{ item.description }}
              </p>
            </div>
          </editor-list-item>
        </template>
      </editor-list>
    </div>
  </div>
</template>
<script>
import EditorList from 'Editors/EditorList'
import EditorListItem from 'Editors/EditorListItem'
import EditorTipTap from 'Editors/EditorTipTap'
export default {
  components: {
    EditorList,
    EditorTipTap,
    EditorListItem,
  },
  data() {
    return {
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'description',
          label: 'Opis',
          type: 'textarea',
        },
        {
          name: 'image',
          label: 'Slika',
          type: 'image',
        },
      ],
    }
  },
}
</script>
<style scoped>
::v-deep img {
  @apply h-full w-full object-cover object-center;
}

.image-container {
  @apply w-full;
  @screen md {
    @apply w-1/2 pl-4 text-center;
  }
}

.text-container {
  @screen md {
    @apply w-1/2 pl-4 text-center;
  }
}

.item-title {
  @apply font-pragmatica-extended font-bold text-2xl mb-6;
}

.description-text {
  @apply font-pragmatica text-xl;
}

@media only screen and (min-width: 768px) {
  .image-container {
    @apply w-full h-50rem mb-10;
  }

  .text-container {
    @apply w-full pl-0;
  }

  .item-title {
    @apply text-2rem;
  }

  .description-text {
    @apply text-1.6rem;
  }
}
</style>
