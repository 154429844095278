<template>
  <span class="star-icon">
    <i
      v-for="i of 5"
      :key="i"
      :class="[
        { activeHover: returnValue },
        +rating === 0 && i <= hoverIndex
          ? 'filled icon-icon-filled-star'
          : 'icon-ic-actions-star',
        rating === 0 && i <= index && !hoverIndex
          ? 'filled icon-icon-filled-star'
          : 'icon-ic-actions-star',
        i <= rating && index === 0
          ? 'filled icon-icon-filled-star'
          : 'icon-ic-actions-star',
        { bg_gray: bgGray },
      ]"
      @click="returnValue && setActiveStar(i)"
      @mouseenter="returnValue && setActiveHoverStar(i)"
      @mouseleave="returnValue && setActiveHoverStar(0)"
    ></i>
  </span>
</template>

<script>
export default {
  props: {
    rating: {
      type: [Number, String],
      default: 0,
    },
    bgGray: {
      type: Boolean,
      default: false,
    },
    returnValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      index: 0,
      hoverIndex: 0,
    }
  },
  watch: {
    rating(v) {
      if (v === 0) {
        this.setActiveStar(v)
        this.setActiveHoverStar(v)
      }
    },
  },
  methods: {
    setActiveStar(index) {
      this.index = index
      if (index) {
        this.$emit('change', index)
      }
    },
    setActiveHoverStar(index) {
      this.hoverIndex = index
    },
  },
}
</script>

<style lang="scss" scoped>
.star-icon {
  cursor: pointer;
  i {
    @apply inline-block overflow-hidden mr-2;
    max-width: 19px !important;
  }
  i:before {
    @apply text-starGray inline-block;
    font-size: 20px;
    max-width: 19px !important;
  }

  i.filled {
    &:before {
      @apply text-pureYellow;
    }

    &.bg_gray {
      &:before {
        @apply text-pureYellow;
      }
    }
  }

  i.icon-icon-filled-star {
    &:before {
      content: '\ea22' !important;
    }
  }
}

i.activeHover {
  &:hover {
    &:before {
      content: '\ea22' !important;
      color: #3b7093 !important;
    }
  }
}
</style>
