
import s0 from '~/.nuxt/gb-cms/sections/AboutSection'
import s1 from '~/.nuxt/gb-cms/sections/TransitionExpand'
import s2 from '~/.nuxt/gb-cms/sections/ContactSection'
import s3 from '~/.nuxt/gb-cms/sections/CroatianDistributersSection'
import s4 from '~/.nuxt/gb-cms/sections/InternationalDistributersSection'
import s5 from '~/.nuxt/gb-cms/sections/FaqSection'
import s6 from '~/.nuxt/gb-cms/sections/HomePageHeroSection'
import s7 from '~/.nuxt/gb-cms/sections/HomePageSmartCardSection'
import s8 from '~/.nuxt/gb-cms/sections/HomePageSmartNeedleSection'
import s9 from '~/.nuxt/gb-cms/sections/HomePageVideoSection'
import s10 from '~/.nuxt/gb-cms/sections/PrivacyPolicySection'
import s11 from '~/.nuxt/gb-cms/sections/BackgroundImageSection'
import s12 from '~/.nuxt/gb-cms/sections/DeckGallerySection'
import s13 from '~/.nuxt/gb-cms/sections/DescriptionGallerySection'
import s14 from '~/.nuxt/gb-cms/sections/ProductDescriptionSection'
import s15 from '~/.nuxt/gb-cms/sections/ProductImageGridSection'
import s16 from '~/.nuxt/gb-cms/sections/ProductSection'
import s17 from '~/.nuxt/gb-cms/sections/ReviewAddFormSection'
import s18 from '~/.nuxt/gb-cms/sections/SingleImageSection'
import s19 from '~/.nuxt/gb-cms/sections/SmartCardDetailsSection'
import s20 from '~/.nuxt/gb-cms/sections/SmartCardHeroSection'
import s21 from '~/.nuxt/gb-cms/sections/SmartNeedleDetailsSection'
import s22 from '~/.nuxt/gb-cms/sections/SmartNeedleHeroSection'
import s23 from '~/.nuxt/gb-cms/sections/TestimonialsSection'

const sectionMapper = {
	'about-section': s0,
	'transition-expand': s1,
	'contact-section': s2,
	'croatian-distributers-section': s3,
	'international-distributers-section': s4,
	'faq-section': s5,
	'home-page-hero-section': s6,
	'home-page-smart-card-section': s7,
	'home-page-smart-needle-section': s8,
	'home-page-video-section': s9,
	'privacy-policy-section': s10,
	'background-image-section': s11,
	'deck-gallery-section': s12,
	'description-gallery-section': s13,
	'product-description-section': s14,
	'product-image-grid-section': s15,
	'product-section': s16,
	'review-add-form-section': s17,
	'single-image-section': s18,
	'smart-card-details-section': s19,
	'smart-card-hero-section': s20,
	'smart-needle-details-section': s21,
	'smart-needle-hero-section': s22,
	'testimonials-section': s23
}

export default sectionMapper
