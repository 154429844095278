<template>
  <editor-image
    ref="editimg"
    in-background
    name="pozadina"
    class="relative bg-no-repeat bg-100% lg:bg-cover overflow-hidden"
    style="background-image: none !important"
  >
    <img
      v-if="imgPath && imgPath.length"
      :src="imgPath"
      alt="bg image"
      class="absolute inset-0 w-full h-full"
    />
    <div
      class="smartNeedleDetailsSec bg-hexagon-background--dis relative bg-darkGrayishViolet--dis bg-center bg-cover bg-no-repeat sm:bg-auto md:flex md:items-center md:justify-center"
    >
      <div class="absolute inset-0 flex flex-col w-full h-full">
        <div
          class="h-full w-full relative text-white flex items-center justify-center"
        >
          <!-- IMAGE -->
          <div
            class="h-full w-full top-0 left-0 grid grid-cols-2 mx-auto px-2 lg:px-4 xl:max-w-80vw"
          >
            <!-- LEFT COLUMN -->
            <div class="relative h-full pr-10 lg:pr-20">
              <div class="relative h-full">
                <div class="absolute md:right-60px" style="top: 28%">
                  <editor-tip-tap
                    name="description1Title"
                    class="description-title mb-5"
                  />
                  <editor-tip-tap
                    name="description1Text"
                    class="description-text"
                  />
                </div>
                <div class="absolute md:right-60px" style="top: 60%">
                  <editor-tip-tap
                    name="description2Title"
                    class="description-title mb-5"
                  />
                  <editor-tip-tap
                    name="description2Text"
                    class="description-text"
                  />
                </div>
              </div>
            </div>
            <!-- RIGHT COLUMN -->
            <div class="h-full pl-10 lg:pl-20">
              <div class="relative h-full">
                <div class="absolute right-0" style="top: 14%">
                  <editor-tip-tap
                    name="description3Title"
                    class="description-title mb-5"
                  />
                  <editor-tip-tap
                    name="description3Text"
                    class="description-text"
                  />
                </div>
                <div class="absolute right-0" style="top: 40%">
                  <editor-tip-tap
                    name="description4Title"
                    class="description-title mb-5"
                  />
                  <editor-tip-tap
                    name="description4Text"
                    class="description-text"
                  />
                </div>
                <div class="absolute" style="top: 71%; right: 10%">
                  <editor-tip-tap
                    name="description5Title"
                    class="description-title mb-5"
                  />
                  <editor-tip-tap
                    name="description5Text"
                    class="description-text"
                  />
                </div>
              </div>

              <!-- CERTIFICATES -->
              <div
                v-if="false"
                class="absolute bottom-0 transform translate-y-1/2 flex items-start space-x-5 sm:space-x-8 lg:space-x-10"
              >
                <a
                  v-tooltip="{
                    content: certificateTooltipContent(252),
                  }"
                  :href="
                    isCroatianLanguage
                      ? '/documents/certificate-252-hr.pdf'
                      : '/documents/certificate-252-en.pdf'
                  "
                  target="_blank"
                >
                  <img
                    src="/images/certificates/certificate252.png"
                    class="transitioned h-24 w-32 md:h-32 md:w-40 xl:h-12.5rem xl:w-15rem transform hover:scale-105"
                  />
                </a>

                <a
                  v-tooltip="{
                    content: certificateTooltipContent(251),
                  }"
                  :href="
                    isCroatianLanguage
                      ? '/documents/certificate-251-hr.pdf'
                      : '/documents/certificate-251-en.pdf'
                  "
                  target="_blank"
                >
                  <img
                    src="/images/certificates/certificate251.png"
                    class="transitioned h-24 w-32 md:h-32 md:w-40 xl:h-12.5rem xl:w-15rem transform hover:scale-105"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </editor-image>
</template>

<script>
import EditorTipTap from 'Editors/EditorTipTap'
import EditorImage from 'Editors/EditorImage'
import { VTooltip } from 'v-tooltip'

export default {
  components: {
    EditorTipTap,
    EditorImage,
  },

  directives: {
    tooltip: VTooltip,
  },
  data() {
    return {
      imgPath: '',
    }
  },
  computed: {
    isCroatianLanguage() {
      return this.$application.language.code2 === 'hr'
    },
  },
  mounted() {
    if (
      this.$refs.editimg &&
      this.$refs.editimg.sectionMeta &&
      this.$refs.editimg.sectionMeta.pozadina &&
      this.$refs.editimg.sectionMeta.pozadina.newPath
    ) {
      this.imgPath = this.$refs.editimg.sectionMeta.pozadina.newPath
    }
  },
  methods: {
    certificateTooltipContent(number) {
      return `<p>${this.$t('hsc.certificates.certificate')} ${number}:
       <span class="font-semibold uppercase">${this.$t(
         `hsc.certificates.${number}`
       )}</span>
      <br/><span class="italic">${this.$t(
        'hsc.certificates.clickToOpen'
      )}</span>
      </p>`
    },
  },
}
</script>

<style scoped>
.description-title {
  @apply w-full font-pragmatica-extended leading-tight font-bold text-sm;
}

.description-text {
  @apply w-full text-xs;
}
img {
  width: calc(100vw + 200px);
  min-width: calc(100vw + 200px);
  margin-left: -90px;
  margin-right: -100px;
  @apply object-cover;
}

@media only screen and (min-width: 640px) {
  .description-title {
    @apply text-xl;
  }

  .description-text {
    @apply text-base;
  }
}

@media only screen and (min-width: 768px) {
  .description-title {
    @apply text-3xl;
  }

  .description-text {
    @apply text-xl;
  }
}

@media only screen and (min-width: 1024px) {
  .description-title {
    @apply text-2rem;
  }

  .description-text {
    @apply text-1.6rem;
  }
}
.smartNeedleDetailsSec {
  min-height: 120vh;
  @media only screen and (max-width: 560px) {
    min-height: 140vw;
  }
  @media only screen and (max-width: 420px) {
    min-height: 160vw;
  }
  @media only screen and (max-width: 370px) {
    min-height: 180vw;
  }
}
</style>
