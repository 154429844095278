<template>
  <section id="video-section">
    <editor-image
      in-background
      name="pozadina"
      hide-controls
      autoplay
      style="padding-bottom: 34.25%"
    />
    <div class="h-2 bg-pureYellow"></div>
  </section>
</template>
<script>
import EditorImage from 'Editors/EditorImage'
export default {
  components: {
    EditorImage,
  },
  mounted() {
    setTimeout(() => {
      const video = document.querySelector('video')
      if (!video) return
      video.muted = true
      video.loop = true
      video.play()
    }, 222)
  },
}
</script>
<style lang="scss" scoped>
::v-deep .video-wrapper {
  video {
    @apply w-full;
  }
  @apply absolute inset-0 w-full h-full;
}
</style>
